/* @tailwind base;
@tailwind components;
@tailwind utilities; */

.border-blue-400i {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
}
.rct-tree-root {
  background-color: #fff !important;
}
.rct-tree-root-focus {
  outline: none !important;
}

.itr-hover\:wrapper:hover .itr-hover\:hidden {
  display: none;
}
.itr-hover\:wrapper:hover .itr-hover\:block {
  display: block;
}
.itr-hover\:wrapper:hover .itr-hover\:flex {
  display: flex;
}

.RFS-StepButton {
  background-color: rgba(96, 165, 250) !important;
}

.RFS-StepButton.completed {
  background-color: rgba(37, 99, 235) !important;
}